var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable camelcase */
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ApiHttp from "byzantine/src/ApiHttp";
import { useNotificationContext, LoadingSkeleton } from "cerulean";
import styles from "./ReplaceACardContainer.module.scss";
import SudoAction from "../../../SudoAction";
import TfaVerify from "../../../verify/TfaVerify";
import useReviewableAction from "../../../useReviewableAction";
import ConfirmDetails from "./ConfirmDetails/ConfirmDetails";
import ReplaceReasonPage from "./ReplaceReasonPage";
var ReplaceACardContainer = function () {
    var _a = __read(useState(3), 2), totalSteps = _a[0], setTotalSteps = _a[1];
    var _b = __read(useState(null), 2), card = _b[0], setCard = _b[1];
    var _c = __read(useState(""), 2), reason = _c[0], setReason = _c[1];
    var cardId = useParams().cardId;
    var reviewableAction = useReviewableAction();
    var navigate = useNavigate();
    var sendNotification = useNotificationContext().sendNotification;
    useEffect(function () {
        // if the sudo check succeeds, then we'll only have 2 steps
        ApiHttp.fetch("sudo")
            .then(function () {
            setTotalSteps(2);
        })
            .catch(function () {
            setTotalSteps(3);
        });
    }, []);
    useEffect(function () {
        var requestCard = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, ApiHttp.fetch("cards/".concat(cardId), { method: "GET", headers: { "API-Version": "0017" } }, {})];
                    case 1:
                        response = _a.sent();
                        if (!response) {
                            throw new Error("Card not found");
                        }
                        setCard(response);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        sendNotification({
                            type: "negative",
                            text: "There was an error fetching your card.",
                        });
                        navigate("/settings/cards");
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        requestCard();
    }, []);
    var checkSudo = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!reason) {
                        sendNotification({
                            type: "negative",
                            text: "Please select a card replacement reason.",
                        });
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, ApiHttp.fetch("sudo")];
                case 2:
                    _a.sent();
                    reviewableAction.goToReview();
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    reviewableAction.goToSudo();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    if (!card) {
        return null;
    }
    return (_jsx(LoadingSkeleton, { isLoading: !card, children: _jsx(SudoAction, { reviewableAction: reviewableAction, renderInput: 
            // for now, this div just undoes the extra padding added by the semantic ui here:
            // https://github.com/narmi/banking/blob/21472c9faf594607f5cedee21742e2a2885a1965/indigo/templates/indigo/settings.html#L15
            // we also apply this same workaround to the components rendered via renderSudo and renderReview
            _jsx("div", { style: { marginLeft: "-1em", marginRight: "-1em" }, children: _jsx("div", { className: styles.container, children: _jsx("div", { className: styles.mobileWebExtraSpace, children: _jsx(ReplaceReasonPage, { reason: reason, totalSteps: totalSteps, setReason: setReason, onSubmit: checkSudo }) }) }) }), renderSudo: _jsx("div", { style: { marginLeft: "-1em", marginRight: "-1em" }, children: _jsx(TfaVerify, { goToReview: reviewableAction.goToReview, cancelAction: reviewableAction.goToInput, step: 2, totalSteps: 3 }) }), renderReview: _jsx("div", { style: { marginLeft: "-1em", marginRight: "-1em" }, children: _jsx("div", { className: styles.container, children: _jsx("div", { className: styles.mobileWebExtraSpace, children: _jsx(ConfirmDetails, { card: card, goToInput: reviewableAction.goToInput, goToSudo: reviewableAction.goToSudo, reason: reason, stepNumber: totalSteps }) }) }) }) }) }));
};
export default ReplaceACardContainer;
