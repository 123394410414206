import React, { useEffect, useState, useContext } from "react";
import { useLocalization } from "@fluent/react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import ApiHttp from "byzantine/src/ApiHttp";
import {
  ContextForm,
  Dialog,
  NotificationContext,
  useFormData,
} from "cerulean";
import { TextInput, Button, Select, formatNumber } from "@narmi/design_system";

const ProgressBar = ({ completed }) => (
  <div className="progress_bar">
    <div
      className="progress_fill"
      style={{
        width: `${completed}%`,
      }}
    />
  </div>
);
ProgressBar.propTypes = {
  completed: PropTypes.number,
};

const File = ({ file, removeFile, uploadFailed }) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  useEffect(() => {
    // if upload request fails or finishes, the bar should be set to 0 or 100
    if (uploadFailed || file.progress === 100) {
      setUploadProgress(file.progress);
      return;
    }
    setUploadProgress(99.9);
  }, [file.progress, uploadFailed]);

  return (
    <div className="transaction_issue_file_upload">
      <div
        style={{
          visibility: file.fileName ? "visible" : "hidden",
        }}
        className="file_name"
        href={file.url}
      >
        {file ? (
          <span style={{ padding: "8px 12px" }}>{`${file.fileName} (${
            Math.round((file.fileSize / 1024) * 1000) / 1000
          }k)`}</span>
        ) : (
          ""
        )}
      </div>
      <div className="progress_bar_container">
        {file.progress !== 100 && !uploadFailed && (
          <ProgressBar completed={uploadProgress} />
        )}
        <span
          className="narmi-icon-x"
          role="button"
          tabIndex="0"
          onClick={removeFile}
          onKeyDown={removeFile}
        ></span>
      </div>
    </div>
  );
};

File.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.number,
    fileName: PropTypes.string,
    fileSize: PropTypes.number,
    progress: PropTypes.number,
    url: PropTypes.string,
  }),
  removeFile: PropTypes.func,
  uploadFailed: PropTypes.bool,
};

const NewThreadDialog = ({
  accountNumber,
  transactionId,
  isDialogOpen,
  replyTime,
  closeDialog,
  callback,
}) => {
  const { l10n } = useLocalization();
  const [files, setFiles] = useState([]);
  const [teams, setTeams] = useState([]);
  const [teamValue, setTeamValue] = useState();
  const [transaction, setTransaction] = useState("");
  const [uploadFailed, setUploadFailed] = useState(false);
  const { sendNotification } = useContext(NotificationContext);
  const { formData, setFormData, onChange } = useFormData({
    subject: transactionId
      ? l10n.getString("message-subject-account-question")
      : "",
  });
  const hiddenFileInput = React.useRef(null);
  const getTransactionDetails = () => {
    ApiHttp.fetch(`transactions/${transactionId}`)
      .then((response) => {
        const amountDisplay = formatNumber(response.transaction.amount / 100);
        const dateDisplay = DateTime.fromJSDate(
          new Date(response.transaction.settled_at)
        ).toFormat("MM/dd/yyyy");
        const transaction_details = l10n.getString(
          "message-transaction-details",
          {
            transactionDate: dateDisplay,
            transactionDescription: response.transaction.description,
            transactionAmount: amountDisplay,
            transactionAccount: accountNumber,
          }
        );
        setFormData({ ...formData, trn_details: transaction_details });
        setTransaction(transaction_details);
      })
      .catch(() => {
        sendNotification({
          type: "negative",
          text: l10n.getString("error-failed-transaction-details"),
        });
      });
  };

  const getTeams = () => {
    ApiHttp.fetch("/teams/", {
      endpointIsUrl: true,
      method: "GET",
    })
      .then((response) => {
        if (response.teams.length) {
          setTeams(response.teams);
          setTeamValue(response.teams[0].uuid);
        }
      })
      .catch(() => {
        sendNotification({
          type: "negative",
          text: l10n.getString("error-failed-transaction-recipient"),
        });
      });
  };

  useEffect(() => {
    if (isDialogOpen) {
      if (transactionId) {
        getTransactionDetails();
      }
      getTeams();
    }
  }, [isDialogOpen]);

  const removeFile = (fileId) => {
    hiddenFileInput.current.value = "";
    setFiles((submittedFiles) =>
      submittedFiles.filter((currentFile) => currentFile.id !== fileId)
    );
  };

  const uploadFile = (fileObject, newFile) => {
    const newFileObject = fileObject;
    ApiHttp.fetch(
      `thread_files/policies/`,
      { method: "POST" },
      { type: newFile.type, name: newFile.name }
    )
      .then((policyResponse) => {
        const formPolicyData = new FormData();
        Object.keys(policyResponse).forEach((key) => {
          if (key !== "form_action") {
            formPolicyData.append(key, policyResponse[key]);
          }
        });
        formPolicyData.append("file", newFile);
        ApiHttp.fetch(
          policyResponse.form_action,
          {
            endpointIsUrl: true,
            method: "POST",
            onUploadProgress: () => {
              const currentFiles = files.filter(
                (currentFile) => currentFile.id !== newFileObject.id
              );
              setFiles([...currentFiles, newFileObject]);
            },
          },
          formPolicyData
        ).then((fileUploadResponse) => {
          const tree = new DOMParser().parseFromString(
            fileUploadResponse,
            "text/xml"
          );
          const tag = tree.getElementsByTagName("Location")[0];
          const url = decodeURIComponent(tag.childNodes[0].nodeValue);
          newFileObject.url = url;
          newFileObject.progress = 100;
          const currentFiles = files.filter(
            (currentFile) => currentFile.id !== newFileObject.id
          );
          setFiles([...currentFiles, newFileObject]);
          if (hiddenFileInput.current && hiddenFileInput.current.value) {
            hiddenFileInput.current.value = "";
          }
        });
      })
      .catch(() => {
        setUploadFailed(true);
        removeFile(newFileObject.id);
        sendNotification({
          type: "negative",
          text: l10n.getString("error-unknown"),
        });
      });
  };
  const onSubmit = (formCallback) => {
    const body = formData.trn_details
      ? formData.body
          .concat("\n---------------------------------------------------\n")
          .concat(formData.trn_details)
      : formData.body;
    return ApiHttp.fetch(
      "threads",
      { method: "POST" },
      {
        subject: formData.subject,
        team_uuid: teamValue,
        body,
        url: files?.[0]?.url,
      }
    )
      .then(() => {
        sendNotification({
          type: "success",
          text: l10n.getString("message-notif-sent"),
        });
        setFiles([]);
        setFormData({
          subject: transactionId
            ? l10n.getString("message-subject-account-question")
            : "",
        });
        if (callback) {
          callback();
        }
        closeDialog();
        formCallback();
      })
      .catch((err) => {
        formCallback(err);
      });
  };
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    let currentIndex = 0;
    if (files.length) {
      currentIndex = Math.max(...files.map((x) => x.id)) + 1;
    }
    Array.from(event.target.files).forEach((file, index) => {
      const newFileObject = {
        id: index + currentIndex,
        progress: 0,
        fileSize: file.size,
        fileName: file.name,
      };
      setFiles((existingFiles) => [...existingFiles, newFileObject]);
      uploadFile(newFileObject, file);
    });
  };

  return (
    <Dialog
      isOpen={isDialogOpen}
      onUserDismiss={() => {
        setFiles([]);
        setFormData({});
        closeDialog();
      }}
      title={l10n.getString("dialog-title-new-message")}
      width="700px"
    >
      <ContextForm
        id="messages--compose-form"
        data={formData}
        onChange={onChange}
      >
        <ContextForm.Field required style={{ marginBottom: "var(--space-xs)" }}>
          <TextInput
            field="subject"
            data-testid="new-thread-subject"
            placeholder="Type a subject..."
          />
        </ContextForm.Field>
        <div className="report_issue_modal_text border--all rounded--all--s">
          <ContextForm.Field required>
            <TextInput
              field="body"
              placeholder="Type a message"
              data-testid="new-thread-body"
              multiline
              style={{ border: "None", minHeight: "134px" }}
            />
          </ContextForm.Field>
          {files.length ? (
            <div style={{ marginBottom: "40px" }}>
              {files.map((file, i) => (
                <File
                  file={file}
                  key={i}
                  removeFile={() => removeFile(file.id)}
                  uploadFailed={uploadFailed}
                />
              ))}
            </div>
          ) : (
            ""
          )}
          {transaction ? (
            <>
              <hr style={{ width: "96%" }} />
              <p className="transaction_details">{transaction}</p>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="alignChild--right--center">
          <ContextForm.Field
            field="file_urls"
            name="file_urls"
            style={{ display: "none" }}
          >
            <input
              type="file"
              defaultValue={""}
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ visibility: "hidden" }}
            />
          </ContextForm.Field>
          <div className="report_issue_footer">
            <div className="children">
              {teams.length > 1 ? (
                <div className="select_wrapper" data-testid="team-select">
                  <Select
                    defaultValue={teams[0].uuid}
                    onChange={(e) => {
                      setTeamValue(e);
                    }}
                  >
                    {teams.map((team, i) => (
                      <Select.Item key={i} value={team.uuid}>
                        {team.name}
                      </Select.Item>
                    ))}
                  </Select>
                </div>
              ) : (
                ""
              )}
              {!files?.length && (
                <span
                  className="narmi-icon-paperclip file_upload"
                  onClick={handleClick}
                  onKeyPress={handleClick}
                  alt="attachment"
                  tabIndex={0}
                  role="button"
                ></span>
              )}
              <ContextForm.Action onSubmit={onSubmit}>
                <Button>{l10n.getString("button-submit")}</Button>
              </ContextForm.Action>
            </div>
            <span className="average_time_span">
              {replyTime
                ? l10n.getString("message-reply-time-setting", {
                    replyTime: replyTime.toLowerCase(),
                  })
                : l10n.getString("message-reply-time-hours", {
                    replyTimeHours: 24,
                  })}
            </span>
          </div>
        </div>
      </ContextForm>
    </Dialog>
  );
};

NewThreadDialog.propTypes = {
  accountNumber: PropTypes.string,
  transactionId: PropTypes.string,
  isDialogOpen: PropTypes.bool.isRequired,
  replyTime: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired,
  callback: PropTypes.func,
};

export default NewThreadDialog;
